import styled from 'styled-components'
import { Layout } from 'antd'

const { Content, Sider } = Layout

export const CustomSider = styled(Sider)<{
  backgroundColor: string
  secondaryColor: string
}>`
  background: ${(props) => props.backgroundColor} !important;
  height: 100vh;
  overflow: hidden;

  .ant-layout-sider-trigger {
    background: ${(props) => props.secondaryColor} !important;
  }
`

export const CustomContent = styled(Content)`
  height: calc(100vh - 60px);
  //overflow: hidden;

  .ant-drawer-body {
    padding: 5px 0 !important;
  }
`
export const FooterImage = styled.img`
  width: 80px;
  height: auto;
`
