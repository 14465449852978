import { FormWrapper, FromTitle, Wrapper } from './styled'

import React from 'react'
import { Alert, Button, Form, Input, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGetAPIStatusQuery, useLoginMutation } from 'redux/apiSlice'

const Login: React.FC = (props) => {
  const navigate = useNavigate()

  const [loginFn, { isLoading: isLoginLoading }] = useLoginMutation()
  const { isError: hasLlmError } = useGetAPIStatusQuery('', {
    refetchOnMountOrArgChange: true
  })
  const onFinish = async (values: any) => {
    try {
      console.log('Success:', values)
      const accessData = await loginFn({
        username: values.username,
        password: values.password
      }).unwrap()
      if (accessData) {
        localStorage.setItem('AccessToken', accessData.access_token as string)
        localStorage.setItem('isAuthenticated', JSON.stringify(true))
        navigate('/')
      }
    } catch (e) {
      console.log('Failed:', e)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  type FieldType = {
    username?: string
    password?: string
    remember?: string
  }

  return (
    <Wrapper>
      <FormWrapper bordered={false}>
        <FromTitle>Welcome to stc Knowledge Companion</FromTitle>

        {hasLlmError && (
          <Alert
            style={{ marginBottom: '30px' }}
            message='Warning'
            type='warning'
            showIcon
            closable
            description='This application is temporarily down, It will be running from 8:00 to 18:00 Riyadh time.'
          />
        )}

        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item<FieldType>
            name='username'
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder='Username' />
          </Form.Item>

          <Form.Item<FieldType>
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder='Password' />
          </Form.Item>

          <Row justify={'space-between'}>
            <Form.Item wrapperCol={{ span: 3 }}>
              <Button type='default'>Help</Button>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 2, span: 3 }}>
              <Button type='primary' htmlType='submit' loading={isLoginLoading}>
                Login
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </FormWrapper>
    </Wrapper>
  )
}

export default Login
