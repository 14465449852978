import React, { useContext, useMemo, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Col,
  message,
  notification,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Upload as AUpload,
  UploadProps
} from 'antd'
import { config } from 'services/config'
import { GroupContext } from 'redux/contexts'
import { useDeleteFileMutation, useGetFilesQuery } from 'redux/apiSlice'
import FileDrawer from '../Assistant/components/FileDrawer/FileDrawer'
import styled from 'styled-components'
import { UploadFile } from 'antd/es/upload/interface'
import MetadataDrawer from './MetadataDrawer'
import { RFile } from '../../models/file'
import { BiTrash } from 'react-icons/bi'

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: scroll;
`
const UploadView = styled(Row)`
  padding-top: 40px;
  height: 400px;
`

const mimeTypeToExtension: { [key: string]: string } = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word Document (.docx)',
  'application/msword': 'Word Document (.doc)',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'Excel Spreadsheet (.xlsx)',
  'application/vnd.ms-excel': 'Excel Spreadsheet (.xls)',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint Presentation (.pptx)',
  'application/vnd.ms-powerpoint': 'PowerPoint Presentation (.ppt)',
  'application/pdf': 'PDF Document',
  'image/jpeg': 'JPEG Image',
  'image/png': 'PNG Image',
  'image/gif': 'GIF Image',
  'image/svg+xml': 'SVG Image',
  'image/webp': 'WEBP Image',
  'image/bmp': 'Bitmap Image',
  'image/tiff': 'TIFF Image',
  'text/plain': 'Text File',
  'text/csv': 'CSV File',
  'text/html': 'HTML Document',
  'text/css': 'CSS File',
  'text/javascript': 'JavaScript File',
  'application/json': 'JSON File',
  'application/xml': 'XML Document',
  'application/zip': 'ZIP Archive',
  'application/x-rar-compressed': 'RAR Archive',
  'application/x-7z-compressed': '7z Archive',
  'application/x-tar': 'TAR Archive',
  'audio/mpeg': 'MP3 Audio',
  'audio/wav': 'WAV Audio',
  'audio/ogg': 'OGG Audio',
  'video/mp4': 'MP4 Video',
  'video/mpeg': 'MPEG Video',
  'video/ogg': 'OGG Video',
  'video/webm': 'WEBM Video',
  'application/octet-stream': 'Binary File'
  // ... add more types as needed
}

const Upload: React.FC = () => {
  const { Dragger } = AUpload
  const group = useContext(GroupContext)
  const {
    data: filesData,
    error,
    refetch
  } = useGetFilesQuery(group.value!, { skip: group.value === '' }) // TODO: get tenant id=
  const [deleteFile] = useDeleteFileMutation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [urlFile, setUrlFile] = useState('')
  const [urlTitle, setUrlTile] = useState('')
  const accessToken = localStorage.getItem('AccessToken')
  const [uploadingFiles, setUploadingFiles] = useState<UploadFile[]>([])

  const [api, contextHolder] = notification.useNotification()

  const props: UploadProps = {
    name: 'files',
    multiple: true,
    fileList: uploadingFiles,
    accept: '.pdf,.docx,.pptx,.png,.jpg,.xlsx,.xls,.csv',
    data: { identifier: group.value },
    action: `${config.BASE_URL}knowledgebase/ingest`,
    headers: { Authorization: accessToken ? `Bearer ${accessToken}` : '' },

    beforeUpload(file, fileList) {
      const isFileSizeValid = file.size / 1024 / 1024 < config.MAX_FILE_SIZE
      if (!isFileSizeValid) {
        message.error(
          `File size must be smaller than ${config.MAX_FILE_SIZE}MB.`
        )
        return false
      }

      const totalFiles = uploadingFiles.length + fileList.length
      if (totalFiles > config.MAX_FILE_COUNT) {
        message.error(
          `You can only upload up to ${config.MAX_FILE_COUNT} files.`
        )
        return false
      }

      return true
    },
    onChange(info) {
      setUploadingFiles([...info.fileList])
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        setUploadingFiles(
          uploadingFiles.filter((file) => file.uid !== info.file.uid)
        )
        // setStatus('done')
        refetch()
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        setUploadingFiles(
          uploadingFiles.filter((file) => file.uid !== info.file.uid)
        )
        refetch()
        message.error(`${info.file.name} file upload failed.`)
      }
    },

    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const onSelectFile = (url: string, title: string) => {
    setUrlFile(url)
    setUrlTile(title)
    setOpenDrawer(true)
  }

  const parseExtFromFile = (type: string) => {
    const extension = mimeTypeToExtension[type]
    return extension !== undefined
      ? extension
      : type.split('/').pop() || 'unknown'
  }

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        render: (title: string, row: RFile) => {
          return (
            <Button
              type={'link'}
              onClick={() => {
                onSelectFile(
                  `https://${config.OSS_BUCKET_NAME}.${config.OSS_ENDPOINT}/${config.OSS_MAIN_DIR}/${row.tenant_id}/${title}`, //https://knowledge-management-bucket.oss-me-central-1.aliyuncs.com/knowledge-base/8da90f0d-9c82-4b78-ad0f-3077a3155ae3/2020-trends-to-watch-higher-education-technology-pdf%20(1).pdf
                  title
                )
              }}
            >
              {title}
            </Button>
          )
        }
      },
      // {
      //   title: 'Uploaded date',
      //   dataIndex: 'created_at',
      //   key: 'created_at',
      //   render: (text: string) => {
      //     return <span>{moment(text).fromNow()}</span>
      //   }
      // },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (type: string) => {
          return <Tag color='magenta'>{parseExtFromFile(type)}</Tag>
        }
      },
      {
        title: 'Actions',
        dataIndex: 'meta_data',
        key: 'meta_data',
        render: (_metadata: any, row: RFile) => {
          return (
            <Space align={'center'}>
              <MetadataDrawer file={row} />
              <Tooltip title={'Delete'}>
                <Popconfirm
                  title={'Are you sure?'}
                  okText={'Yes'}
                  cancelText={'No'}
                  onConfirm={() => {
                    deleteFile({ tenant_id: row.tenant_id, file_id: row.id })
                      .unwrap()
                      .catch(() =>
                        api.error({
                          message: 'Failed',
                          description: 'Error removing file'
                        })
                      )
                  }}
                >
                  <Button icon={<BiTrash />} size={'small'}></Button>
                </Popconfirm>
              </Tooltip>
            </Space>
          )
        }
      }
      // {
      //     title: 'Embedding Reference',
      //     dataIndex: 'embedding_reference',
      //     key: 'embedding_reference',
      // },
      // {
      //     title: 'Published year',
      //     dataIndex: 'published_year',
      //     key: 'published_year',
      // },
      // {
      //     title: 'Hash',
      //     dataIndex: 'hash',
      //     key: 'hash',
      // },
    ],
    []
  )

  return (
    <Wrapper>
      {contextHolder}
      <UploadView justify='center'>
        <Col xs={24} sm={24} md={22} lg={22} xl={22}>
          <Dragger {...props} style={{ height: 300, display: 'block' }}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload additional files to the
              repository
            </p>
            <p className='ant-upload-hint'>Support for PDF files</p>
          </Dragger>
        </Col>
      </UploadView>
      <Row
        justify='center'
        style={{ height: '-webkit-fill-available', marginTop: '100px' }}
      >
        {error && (
          <Alert
            message='Error'
            description={`There is something wrong trying to fetch the ${group.label} files`}
            type='error'
            showIcon
            closable={true}
          />
        )}

        <Col
          xs={24}
          sm={24}
          md={22}
          lg={22}
          xl={22}
          style={{ height: '-webkit-fill-available', marginTop: '100px' }}
        >
          <FileDrawer
            fileUrl={urlFile}
            page={1}
            setOpenDrawer={setOpenDrawer}
            open={openDrawer}
            fileTitle={urlTitle}
          />
          <Table dataSource={filesData} columns={columns} />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Upload
