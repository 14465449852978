import {
  useCreateTenantMutation,
  useDeleteTenantMutation,
  useGetTenantsQuery,
  useUpdateTenantMutation
} from '../../redux/apiSlice'
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  TableColumnsType,
  Typography
} from 'antd'
import styled from 'styled-components'
import { Tenant } from '../../models'
import { BiPencil, BiPlus, BiTrash } from 'react-icons/bi'
import { useState } from 'react'

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow-y: scroll;
`
const Groups = () => {
  const { data, isLoading } = useGetTenantsQuery('', {})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editing, setEditing] = useState<Tenant | undefined>(undefined)
  const [form] = Form.useForm()
  const columns: TableColumnsType<Tenant> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Flex>
          <Button
            type={'default'}
            size={'small'}
            icon={<BiPencil />}
            onClick={() => {
              setEditing(record)
              form.setFieldsValue(record)
              setIsModalOpen(true)
            }}
          ></Button>
          <Popconfirm
            title={'Are you sure?'}
            onConfirm={() => {
              deleteTenant(record.id)
            }}
          >
            <Button
              type={'default'}
              size={'small'}
              danger
              icon={<BiTrash />}
              style={{ marginLeft: 10 }}
            ></Button>
          </Popconfirm>
        </Flex>
      )
    }
  ]

  const [createTenant, { isLoading: isCreating }] = useCreateTenantMutation()
  const [deleteTenant] = useDeleteTenantMutation()
  const [updateTenant, { isLoading: isUpdating }] = useUpdateTenantMutation()
  const handleCreate = async (values: any) => {
    if (editing) {
      await updateTenant({ ...editing, ...values })
    } else {
      await createTenant(values)
    }
    setEditing(undefined)
    form.resetFields()
    setIsModalOpen(false)
  }
  return (
    <Wrapper>
      <Typography.Title level={2}>Tenant Directory</Typography.Title>
      <Flex justify={'flex-end'} style={{ paddingBottom: 20 }}>
        <Button
          type={'primary'}
          icon={<BiPlus />}
          onClick={() => setIsModalOpen(true)}
        >
          New Tenant
        </Button>
      </Flex>
      <Modal
        open={isModalOpen}
        footer={<></>}
        onCancel={() => setIsModalOpen(false)}
      >
        <Typography.Title level={3}>
          {editing ? 'Edit Tenant' : 'New Tenant'}
        </Typography.Title>
        <Form
          onFinish={handleCreate}
          form={form}
          layout={'horizontal'}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 13 }}
        >
          <Form.Item
            label={'Name'}
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Please enter name'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={'Description'} name={'description'}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 13, offset: 5 }}>
            <Button
              htmlType={'submit'}
              type={'primary'}
              loading={isCreating || isUpdating}
            >
              {editing ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Table columns={columns} dataSource={data} loading={isLoading} />
    </Wrapper>
  )
}
export default Groups
