import styled from 'styled-components'
import { Button, Menu, Select } from 'antd'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    font-size: 1.2em !important;
    color: #ffffff !important;
  }

  .ant-select-arrow {
    color: #ffffff !important;
  }

  .ant-select-selection-item {
    color: #ffffff !important;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100vh;
`

export const AppName = styled.div`
  color: var(--text-text-tertiary, #ffffff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 40px 0;
`

export const SideFooter = styled.div`
  padding: 10px;
  color: #ffffff;
  font-size: 1.3em;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  .username {
    margin-left: 12px;
  }
`

export const TenantName = styled.div`
  color: #ffffff;
  font-size: 1.25em;
  margin-top: 12px;
  margin-left: 5px;
`

export const MenuTitle = styled.div`
  color: #ffffff;
  margin-left: 10px;
  opacity: 0.8;
  margin-bottom: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  a {
    color: #ffffff;
    padding: 12px 24px;
    margin: 4px;
    &:hover,
    &.active {
      background-color: #1b052d;
      border-radius: 10px;
    }
    &.active {
      color: rgb(255, 55, 94);
      font-weight: bold;
    }
  }
`

export const ScrollArea = styled(PerfectScrollbar)`
  .ps__rail-y:hover {
    background-color: transparent;
  }
  flex: 1;
  overflow: scroll;
`
export const CustomMenu = styled(Menu)`
  background: transparent;

  .ant-menu-item:hover {
    background-color: #1b052d !important;
  }

  .ant-menu-title-content {
    color: #ffffff !important;
    font-size: 1.1em;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .action-icon {
      display: none;
    }
  }

  .ant-menu-item-selected {
    background-color: #1b052d !important;

    .action-icon {
      display: flex;
    }

    .ant-menu-title-content {
      color: #ff375e !important;
      font-weight: bold;
    }
  }
`

export const MenuItemTitle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;
`

export const AddMessageBtn = styled(Button)`
  float: right;
  font-size: 1.6em;
  color: #ff375e;
`
