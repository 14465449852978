import { Context, createContext } from 'react'
import { ClientProfile, Group, IUser } from 'models'

export const defaultProfile: ClientProfile = {
  appName: 'stc Knowledge Companion',
  showAppName: true,
  logoUrl: 'https://genaiassets.blob.core.windows.net/assets/bcg/bcgx-logo.png',
  thumbnailLogoUrl:
    'https://genaiassets.blob.core.windows.net/assets/bcg/bcgx-thumbnail.png',
  primaryColor: '#270640',
  secondaryColor: '#480988',
  darkPrimaryColor: '#000000',
  mainLogoSize: '90%',
  thumbnailLogoSize: '45px'
}
export const ClientContext: Context<ClientProfile> =
  createContext(defaultProfile)

export const defaultGroup: Group = {
  value: '',
  label: 'Select Group'
}

export const GroupContext: Context<Group> = createContext(defaultGroup)

export const AccessTokenContext: Context<string> = createContext('')
export const CurrentUserContext: Context<IUser> = createContext({} as IUser)
