import React, { useState } from 'react'
import styled from 'styled-components'
import { FaGlobe } from 'react-icons/fa'
import { Button } from 'antd'

import { config } from 'services/config'

const fetchTranslation = async (message: string) => {
  let query = {
    question: message,
    context: 'YourContext',
    chat_prompt:
      '[{"template_type": "HumanMessagePromptTemplate", "prompt": "{\\"template\\": \\"\\", \\"input_variables\\": \\"[]\\"}"}]'
  }
  const response = await fetch(`${config.TRANSLATION_URL}en`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(query)
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json()
  return { translatedText: data['answer'], isSuccess: true }
}
export const translateMessage = async (message: string) => {
  return await fetchTranslation(message)
}

const StyledTranslationButton = styled(Button)`
  transform: scale(0.75);
  background-color: #a891cc;
  color: white;
  border: none;
  &:hover {
    background-color: #e3d4fa;
    color: white;
  }
`

const StyledFaIcon = styled(FaGlobe)`
  color: white;
  &:hover {
    color: white;
  }
`
export const TranslationButton = ({
  id,
  loading
}: {
  id: string
  loading: boolean
}) => {
  return (
    <StyledTranslationButton
      id={id}
      loading={loading}
      htmlType='submit'
      disabled={false}
    >
      <StyledFaIcon />
    </StyledTranslationButton>
  )
}
