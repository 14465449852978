import styled from 'styled-components'
import { useMemo } from 'react'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin: 0.5rem;
  color: #444;
`

const FAQButton = styled.div`
  text-align: left;
  text-wrap: normal;
  border: solid 1px #d9d9d9;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
    border-color: #ff617b;
  }
`

type FAQProps = {
  texts: string[]
  onClick: (text: string) => void
}
const FAQ: React.FC<FAQProps> = ({ onClick, texts }) => {
  // generate 4 randoms numbers within texts
  const randoms = useMemo(() => {
    const randoms: number[] = []
    for (let i = 0; i < 4; i++) {
      const random = Math.floor(Math.random() * texts.length)
      if (randoms.includes(random)) {
        i--
      } else {
        randoms.push(random)
      }
    }
    return randoms
  }, [texts])
  return (
    <Grid>
      {randoms.map((i) => (
        <FAQButton key={i} onClick={() => onClick(texts[i])}>
          {texts[i]}
        </FAQButton>
      ))}
    </Grid>
  )
}

export default FAQ
