import { Button, Checkbox, Collapse, Drawer, Flex, Space } from 'antd'
import { IoOptions } from 'react-icons/io5'
import React, { SetStateAction, useContext } from 'react'
import { useGetFiltersQuery } from '../../../../redux/apiSlice'
import { GroupContext } from '../../../../redux/contexts'

export type FilterOption = Record<string, string[]>

const YEARS = [2018, 2019, 2020, 2021, 2022, 2023]
const AUTHORS = [
  'Ahmed',
  'Mohammed',
  'Ali',
  'Sara',
  'Fatima',
  'Hussain',
  'Abdullah',
  'Khalid',
  'Nasser',
  'Abdulrahman'
]

type FiltersProps = {
  filters: FilterOption
  setFilters: React.Dispatch<SetStateAction<FilterOption>>
}
export const Filters: React.FC<FiltersProps> = ({ setFilters, filters }) => {
  const [showDrawer, setShowDrawer] = React.useState(false)
  const group = useContext(GroupContext)
  const { data: filterOptions } = useGetFiltersQuery(group.value, {
    skip: !group.value
  })

  return (
    <>
      <Drawer
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        title={'Filters'}
      >
        <Flex vertical gap={'small'}>
          <Button.Group size={'middle'} style={{ width: '100%' }}>
            <Button
              block={true}
              type='default'
              size='middle'
              onClick={() => {
                filterOptions &&
                  setFilters(
                    Object.keys(filterOptions || {}).reduce(
                      (acc, cur) => ({
                        ...acc,
                        [cur]: filterOptions[cur]
                      }),
                      {}
                    )
                  )
              }}
            >
              Select All
            </Button>
            <Button
              block={true}
              type='default'
              size='middle'
              onClick={() => {
                setFilters({})
              }}
            >
              Deselect All
            </Button>
          </Button.Group>

          {filterOptions && (
            <Collapse
              defaultActiveKey={['years', 'authors']}
              bordered={false}
              items={Object.keys(filterOptions).map((key) => ({
                key: key,
                label: key.toLocaleUpperCase(),
                children: (
                  <Space direction={'vertical'}>
                    {filterOptions[key].map((option: string) => (
                      <Checkbox
                        key={option}
                        checked={filters[key]?.includes(option)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              [key]: [...(prevFilters[key] || []), option]
                            }))
                          } else {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              [key]:
                                prevFilters[key]?.filter((i) => i !== option) ||
                                []
                            }))
                          }
                        }}
                      >
                        {option}
                      </Checkbox>
                    ))}
                  </Space>
                ),
                extra: (
                  <Checkbox
                    checked={
                      (filters[key]?.length || -1) === filterOptions[key].length
                    }
                    indeterminate={
                      filters[key]?.length > 0 &&
                      filters[key]?.length < filterOptions[key].length
                    }
                    onClick={(e) => {
                      // e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          [key]: filterOptions[key]
                        }))
                      } else {
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          [key]: []
                        }))
                      }
                    }}
                  >
                    All
                  </Checkbox>
                )
              }))}
            />
          )}
        </Flex>
      </Drawer>

      <Button
        type={'text'}
        onClick={() => setShowDrawer(true)}
        size='small'
        icon={<IoOptions size={20} color={'gray'} />}
      />
    </>
  )
}
export default Filters
