import { Button, Drawer, Space } from 'antd'
import PDFViewer from '../Viewer/PDFViewer'
import React, { FC } from 'react'
import { Image } from './styled'
import MSDocViewer from '../Viewer/MSDocViewer'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

interface IProps {
  fileUrl: string
  fileTitle: string
  page: number | string
  open: boolean
  setOpenDrawer: (state: boolean) => void
}

const FileDrawer: FC<IProps> = (props) => {
  const { fileUrl, open, setOpenDrawer, fileTitle, page } = props

  const onClose = () => {
    setOpenDrawer(false)
  }

  const renderFile = () => {
    const ext = fileUrl.substr(fileUrl.lastIndexOf('.') + 1)
    switch (ext.toLowerCase()) {
      case 'pdf':
        return <PDFViewer fileUrl={fileUrl} page={page as number} />
      case 'xlsx':
      case 'xls':
        return <MSDocViewer fileUrl={fileUrl} sheet={page as string} />
      case 'docx':
      case 'doc':
        return <MSDocViewer fileUrl={fileUrl} page={page as number} />
      case 'pptx':
      case 'ppt':
        return <MSDocViewer fileUrl={fileUrl} slide={page} />
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <Image src={fileUrl} />
      default:
        return (
          <DocViewer
            documents={[{ uri: fileUrl }]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true
              }
            }}
          />
        )
    }
  }

  return (
    <Drawer
      title={fileTitle}
      width={'70%'}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80
        }
      }}
      extra={
        <Space>
          <Button type={'primary'} href={fileUrl} target={'_blank'}>
            Download
          </Button>
        </Space>
      }
    >
      {open && renderFile()}
    </Drawer>
  )
}

export default FileDrawer
