import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import {
  AddMessageBtn,
  AppName,
  CustomMenu,
  CustomSelect,
  MenuItemTitle,
  MenuTitle,
  MenuWrapper,
  ScrollArea,
  SideFooter,
  TenantName,
  Wrapper
} from './styled'
import { Avatar, Button, Col, message, Popconfirm, Row, Tooltip } from 'antd'
import { AiOutlineHome, AiOutlineSetting } from 'react-icons/ai'
import { ClientContext, defaultGroup } from 'redux/contexts'
import { Group, IUser } from 'models'
import { useDeleteChatMutation, useGetTenantsQuery } from 'redux/apiSlice'
import {
  CloseCircleFilled,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Chat } from 'models/message'
import { RiChatNewFill } from 'react-icons/ri'
import { useMessageList } from '../../Assistant/components/MessageList/MessageListProvider'

interface IProps {
  selectGroup: (group: Group) => void
  currentUser: IUser
}

const SideContent: FC<IProps> = (props) => {
  const { selectGroup, currentUser } = props
  const profile = useContext(ClientContext)
  const { data: tenantList } = useGetTenantsQuery('', {
    refetchOnMountOrArgChange: true
  })
  const [deleteChatFn] = useDeleteChatMutation()
  const navigate = useNavigate()
  const location = useLocation()
  const [chatHistory, setChatHistory] = useState<Chat[]>([])
  const [selectedKey, setSelectedKey] = useState<string>('NONE')

  const handleChange = useCallback(
    (value: any) => {
      const tenant = tenantList?.find((t) => t.id === value)
      if (tenant) {
        selectGroup({ label: tenant.name, value: tenant.id })
      }
    },
    [selectGroup, tenantList]
  )

  const { chatList, newChat, selectConversation } = useMessageList()

  const handleLogout = () => {
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('currentUser')
    navigate('/login')
  }

  useEffect(() => {
    if (tenantList && tenantList.length > 0) {
      handleChange(tenantList[0].id)
    }
  }, [handleChange, tenantList])

  useEffect(() => {
    if (chatList && chatList.length > 0) {
      setChatHistory(chatList)
    }
  }, [chatList])

  const confirmDeleteChat = async (chatId: string) => {
    await deleteChatFn(chatId)
    message.success('Chat deleted successfully')
    const chats = [...chatHistory]
    const index = chats?.findIndex((chat) => chat.id === chatId)
    if (index !== undefined && index > -1) {
      chats?.splice(index, 1)
    }
    setChatHistory((tem) => [...chats])
  }

  const onCreateNewChat = () => {
    newChat()
    setSelectedKey('NONE')
  }

  const onHandleSelectItem = (key: string) => {
    navigate('/')
    selectConversation(key)
    setSelectedKey(key)
  }

  return (
    <Wrapper>
      <Row gutter={[6, 12]}>
        <Col span={16}>
          {tenantList && tenantList.length > 1 ? (
            <CustomSelect
              defaultValue={
                tenantList && tenantList?.length > 0
                  ? tenantList[0].id
                  : defaultGroup
              }
              style={{ width: '100%' }}
              placement='topLeft'
              onChange={handleChange}
              options={tenantList?.map((tenant) => ({
                value: tenant.id,
                label: tenant.name
              }))}
            />
          ) : (
            tenantList &&
            tenantList.length > 0 && (
              <TenantName>{tenantList[0].name}</TenantName>
            )
          )}
        </Col>

        <div>
          <div
            style={{
              border: '1px solid #A5A5A9',
              height: '100%',
              margin: '0 10px'
            }}
          />
        </div>

        <Col>
          <Button
            type='text'
            icon={<AiOutlineHome size={26} color='#FFFFFF' />}
            size='middle'
            style={{ bottom: '3px', color: '#FFF' }}
            onClick={() => navigate('/')}
          />
        </Col>

        <Col>
          <Button
            type='text'
            icon={<AiOutlineSetting size={26} color='#FFFFFF' />}
            size='middle'
            style={{ bottom: '3px' }}
            onClick={() => navigate('/settings/uploads')}
          />
        </Col>
      </Row>

      <AppName>{profile.appName}</AppName>
      {location.pathname.includes('settings') ? (
        <MenuWrapper>
          <NavLink to={'/settings/uploads'}>Knowledge Base</NavLink>
          {currentUser.role === 'admin' && (
            <>
              <NavLink to={'/settings/users'}>Users</NavLink>
              <NavLink to={'/settings/groups'}>Settings</NavLink>
            </>
          )}
        </MenuWrapper>
      ) : (
        <MenuWrapper>
          {chatHistory && chatHistory?.length > 0 && (
            <>
              <MenuTitle>
                Your Chats
                <Tooltip placement='right' title={'New chat'} arrow={true}>
                  <AddMessageBtn onClick={onCreateNewChat} type='link'>
                    <RiChatNewFill />
                  </AddMessageBtn>
                </Tooltip>
              </MenuTitle>

              <ScrollArea>
                <CustomMenu
                  mode='inline'
                  selectedKeys={[selectedKey]}
                  onClick={(e) => {
                    onHandleSelectItem(e.key)
                  }}
                  items={chatHistory?.map((chat) => ({
                    key: chat.id,
                    label: (
                      <>
                        <MenuItemTitle>{chat.title}</MenuItemTitle>
                        <Popconfirm
                          placement='right'
                          title={'Are you sure to delete this chat?'}
                          description={'Delete the chat'}
                          okText='Yes'
                          cancelText='No'
                          onConfirm={() => confirmDeleteChat(chat.id)}
                        >
                          <CloseCircleFilled
                            className={'action-icon'}
                            style={{ float: 'right' }}
                          />
                        </Popconfirm>
                      </>
                    )
                  }))}
                />
              </ScrollArea>
            </>
          )}
        </MenuWrapper>
      )}

      {currentUser && (
        <SideFooter>
          <Avatar
            style={{ backgroundColor: '#03c38b' }}
            icon={<UserOutlined />}
            size={54}
          />
          <span className='username'>
            {currentUser.firstname} {currentUser.lastname}
          </span>
          <Button
            type='text'
            icon={
              <LogoutOutlined
                size={32}
                style={{
                  color: '#03c38b',
                  fontSize: '24px',
                  marginLeft: '10px'
                }}
              />
            }
            onClick={handleLogout}
          />
        </SideFooter>
      )}
    </Wrapper>
  )
}

export default SideContent
