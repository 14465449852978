import React, { useContext, useState } from 'react'
import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  notification,
  Space,
  Tooltip
} from 'antd'
import { RFile } from '../../models/file'
import { TiMinus, TiPlus } from 'react-icons/ti'
import { GroupContext } from '../../redux/contexts'
import { useSetMetadataForFileMutation } from '../../redux/apiSlice'
import { BiListUl } from 'react-icons/bi'

type MetadataDrawerProps = {
  file: RFile
}
const MetadataDrawer = ({ file }: MetadataDrawerProps) => {
  const [open, setOpen] = useState(false)
  const { value: tenantId } = useContext(GroupContext)

  const [setMetadata, { isLoading }] = useSetMetadataForFileMutation()

  const [form] = Form.useForm()

  const initialValue = Object.entries(file.meta_data || {}).map(
    ([key, value]) => ({
      key,
      value
    })
  )

  const [api, contextHolder] = notification.useNotification()

  const onFinish = async (values: {
    metadata?: { key: string; value: string }[]
  }) => {
    // filter empty key
    const metadata = values.metadata?.filter((i) => i?.key)
    //convert to object
    const metadataObj = metadata?.reduce(
      (acc, cur) => {
        acc[cur.key.toUpperCase()] = cur.value
        return acc
      },
      {} as Record<string, string>
    )
    try {
      await setMetadata({
        file_id: file.id,
        tenant_id: tenantId || '',
        metadata: metadataObj || {}
      }).unwrap()
      setOpen(false)
    } catch (e: any) {
      api.error({
        message: 'Failed',
        description: 'Could not save metadata to file'
      })
      console.error(e)
    }
  }

  return (
    <>
      {contextHolder}
      <Tooltip title={'Metadata'}>
        <Button
          type={'default'}
          size={'small'}
          icon={<BiListUl size={16} />}
          onClick={() => setOpen(true)}
        ></Button>
      </Tooltip>
      <Form
        form={form}
        name={file.id}
        initialValues={{ metadata: initialValue }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Drawer
          title={file.title}
          placement='right'
          closable={true}
          open={open}
          onClose={() => setOpen(false)}
          footer={
            <Flex justify={'flex-end'} gap={'small'}>
              <Button type={'default'} onClick={() => setOpen(false)}>
                Close
              </Button>
              <Button
                type={'primary'}
                loading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  onFinish(form.getFieldsValue())
                }}
              >
                Save
              </Button>
            </Flex>
          }
        >
          <Form.List name='metadata'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex' }} align='baseline'>
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[{ required: true, message: 'Missing key' }]}
                    >
                      <Input
                        placeholder='Key'
                        style={{ textTransform: 'uppercase' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing value' }]}
                    >
                      <Input placeholder='Value' />
                    </Form.Item>
                    <Button
                      icon={<TiMinus />}
                      type={'text'}
                      onClick={() => remove(name)}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type='default'
                    onClick={() => add()}
                    block
                    icon={<TiPlus />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Drawer>
      </Form>
    </>
  )
}
export default MetadataDrawer
