import styled from 'styled-components'

export const Wrapper = styled.div``

export const Header = styled.div``

export const Title = styled.h1`
  font-size: 2.4em;
  font-weight: 700;
  margin: 50px 0;
`
