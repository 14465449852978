import React, { FC } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/search/lib/styles/index.css'
import styled from 'styled-components'

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`
interface IProps {
  fileUrl: string
  page?: number
  sheet?: any
  slide?: any
}

const MSDocViewer: FC<IProps> = (props) => {
  const { fileUrl, page, sheet, slide } = props

  return (
    <IFrame
      id='msdoc-iframe'
      title='msdoc-iframe'
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        fileUrl
      )}${slide ? `&wdSlideIndex=${slide}` : ''}${
        sheet && typeof sheet === 'string' ? `&activeCell='${sheet}'!A1` : ''
      }${
        page ? `&wdStartOn=${page}` : ''
      }&action=embedview&wdAllowInteractivity=True`}
    />
  )
}
export default MSDocViewer
