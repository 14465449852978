import React, { useContext, useEffect, useState } from 'react'
import { Layout as ALayout } from 'antd'
import { CustomContent, CustomSider, FooterImage } from './styled'

import { Outlet, useNavigate } from 'react-router-dom'
import useTheme from 'theme'
import SideContent from './components/SideContent'
import { GroupContext } from 'redux/contexts'
import { Group, IUser } from 'models'
import { useGetChatsHistoryQuery, useGetCurrentUserQuery } from 'redux/apiSlice'
import stcLogo from 'images/stc-logo.svg'
import bcgxLogo from 'images/01-bcgx-logo.png'
import { MessageListProvider } from '../Assistant/components/MessageList/MessageListProvider'

const { Footer } = ALayout

interface IProps {}

const Layout: React.FC<IProps> = ({}) => {
  // Refetch Chats after login
  useGetChatsHistoryQuery('', {
    refetchOnMountOrArgChange: true
  })

  const { COLORS } = useTheme()
  const [collapsed, setCollapsed] = useState(false)
  const group = useContext(GroupContext)
  const [selectedGroup, setSelectedGroup] = useState<Group>(group)
  const navigate = useNavigate()
  const { data: currentUser, isLoading, isError } = useGetCurrentUserQuery('')

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser as IUser))
    }
    if (isError && !isLoading) {
      localStorage.removeItem('currentUser')
      localStorage.removeItem('AccessToken')
      navigate('/login')
    }
  }, [currentUser, isError, isLoading])

  // useEffect(() => {
  //   setMessageListData((temData) => {
  //     return { ...temData, messages: data || [] }
  //   })
  // }, [data])

  // const onHandleSelectMenuItem = (chatId: string) => {
  //   getMessagesFromChat(chatId)
  //   setMessageListData({ messages: data || [], chatId })
  // }

  // const onHandleNewChat = () => {
  //   setMessageListData({ messages: [], chatId: undefined })
  // }

  return (
    <>
      {isLoading ? (
        <span>Loading Profile</span>
      ) : (
        <ALayout style={{ height: '100vh', backgroundColor: '#f3f9ff' }}>
          <MessageListProvider>
            <CustomSider
              breakpoint='md'
              width='330'
              collapsedWidth='60'
              collapsible={false}
              collapsed={collapsed}
              backgroundColor={COLORS.primary}
              secondaryColor={COLORS.secondary}
              onCollapse={(value) => setCollapsed(value)}
            >
              <SideContent
                selectGroup={setSelectedGroup}
                currentUser={currentUser as IUser}
              />
            </CustomSider>

            <GroupContext.Provider value={selectedGroup}>
              <ALayout style={{ height: '100vh', backgroundColor: '#f3f9ff' }}>
                <CustomContent>
                  <Outlet />
                </CustomContent>
                <Footer
                  style={{
                    height: 56,
                    padding: '1em',
                    textAlign: 'center',
                    backgroundColor: '#f3f9ff',
                    fontSize: '.8em',
                    color: '#A5A5A9'
                  }}
                >
                  Powered By{' '}
                  <FooterImage
                    src={stcLogo}
                    alt={'STC LOGO'}
                    style={{
                      width: '42px',
                      height: 'auto',
                      position: 'relative',
                      bottom: '2px',
                      margin: '0 7px'
                    }}
                  />
                  {/*&*/}
                  {/*<img*/}
                  {/*  src={bcgxLogo}*/}
                  {/*  alt={'BCGX LOGO'}*/}
                  {/*  style={{*/}
                  {/*    width: '80px',*/}
                  {/*    height: 'auto',*/}
                  {/*    position: 'relative',*/}
                  {/*    bottom: '2px'*/}
                  {/*  }}*/}
                  {/*/>*/}
                  ©2023
                </Footer>
              </ALayout>
            </GroupContext.Provider>
          </MessageListProvider>
        </ALayout>
      )}
    </>
  )
}

export default Layout
