// Forked from react-chat-elements
// Added Markdown support and copy button

import React, { useEffect, useRef, useState } from 'react'
import { RiShareForwardFill } from 'react-icons/ri'
import { IoIosDoneAll } from 'react-icons/io'
import {
  MdAccessTime,
  MdBlock,
  MdCheck,
  MdDelete,
  MdDoneAll,
  MdMessage
} from 'react-icons/md'

import { format } from 'timeago.js'

import classNames from 'classnames'
import {
  Avatar,
  MeetingLinkActionButtons,
  MessageBoxType
} from 'react-chat-elements'
import { Badge, Button, ButtonProps, Flex, Spin } from 'antd'
import { IoCopyOutline } from 'react-icons/io5'
import Markdown from 'react-markdown'
import { api, useAddRemoveLikeDislikeMutation } from 'redux/apiSlice'
import remarkGfm from 'remark-gfm'
import { GoThumbsdown, GoThumbsup } from 'react-icons/go'
import { authService } from '../../../../services/auth'

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target='_blank' rel='noreferrer'>
      {props.children}
    </a>
  )
}

type CustomMessageType = MessageBoxType & {
  loading?: boolean
  copyable?: boolean
  customActions?: ButtonProps[]
  likes?: number
  dislikes?: number
}
const MessageBox: React.FC<CustomMessageType> = ({
  focus = false,
  notch = true,
  styles,
  ...props
}) => {
  const prevProps = useRef(focus)
  const messageRef = useRef<HTMLDivElement>(null)
  const [copied, setCopied] = useState(false)
  const [hasLike, setHasLike] = useState(false)
  const [hasDislike, setHasDislike] = useState(false)
  var positionCls = classNames('rce-mbox', {
    'rce-mbox-right': props.position === 'right'
  })
  const [socialValues, setSocialValues] = useState<{
    likes: number
    dislikes: number
  }>({
    likes: props.likes as number,
    dislikes: props.dislikes as number
  })

  const [getLikeDislikeCount, { isLoading: loadingLikeDislikeCount }] =
    api.endpoints?.getLikeDislikeCount?.useLazyQuery()

  const [isLikesDislikeExists] =
    api.endpoints?.isLikesDislikeExists?.useLazyQuery()

  var thatAbsoluteTime =
    !/(text|video|file|meeting|audio)/g.test(props.type || 'text') &&
    !(props.type === 'location' && props.text)
  const dateText = props.date && (props.dateString || format(props.date))

  const [socialFn] = useAddRemoveLikeDislikeMutation()

  const isUUID = (input: string) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    return regexExp.test(input)
  }

  useEffect(() => {
    if (prevProps.current !== focus && focus === true) {
      if (messageRef) {
        messageRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })

        props.onMessageFocused(prevProps)
      }
    }
    prevProps.current = focus
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, prevProps])

  useEffect(() => {
    const fetchSocialNumbers = async () => {
      if (isUUID(props.id as string) && props.position === 'left') {
        const socialValues = await getLikeDislikeCount(
          props.id as string
        ).unwrap()
        setSocialValues(socialValues)
        setHasLike(socialValues.likes > 0)
        setHasDislike(socialValues.dislikes > 0)
      }
    }

    fetchSocialNumbers().catch(console.error)
  }, [])

  const toggleLikeDislike = async (remove: boolean, isLike: boolean) => {
    await socialFn({
      messageId: props.id as string,
      userId: authService.user?.id as string,
      isLike: isLike,
      remove: remove
    }).unwrap()
  }

  const onClickLike = async () => {
    if (hasLike) {
      await toggleLikeDislike(true, true)
      setSocialValues((prev) => ({
        ...prev,
        likes: prev.likes - 1
      }))
      setHasLike(false)
    } else {
      if (hasDislike) {
        await toggleLikeDislike(true, false)
        setSocialValues((prev) => ({
          ...prev,
          dislikes: prev.dislikes - 1
        }))
        setHasDislike(false)
      }

      await toggleLikeDislike(false, true)
      setSocialValues((prev) => ({
        ...prev,
        likes: prev.likes + 1
      }))
      setHasLike(true)
    }
  }

  const onClickDislike = async () => {
    if (hasDislike) {
      await toggleLikeDislike(true, false)
      setSocialValues((prev) => ({
        ...prev,
        dislikes: prev.dislikes - 1
      }))
      setHasDislike(false)
    } else {
      if (hasLike) {
        await toggleLikeDislike(true, true)
        setSocialValues((prev) => ({
          ...prev,
          likes: prev.likes - 1
        }))
        setHasLike(false)
      }

      await toggleLikeDislike(false, false)
      setSocialValues((prev) => ({
        ...prev,
        dislikes: prev.dislikes + 1
      }))
      setHasDislike(true)
    }
  }

  return (
    <div
      ref={messageRef}
      className={classNames('rce-container-mbox', props.className)}
      onClick={props.onClick}
    >
      {props.renderAddCmp instanceof Function
        ? props.renderAddCmp()
        : props.renderAddCmp}

      <div
        style={styles}
        className={classNames(
          positionCls,
          { 'rce-mbox--clear-padding': thatAbsoluteTime },
          { 'rce-mbox--clear-notch': !notch },
          { 'message-focus': focus }
        )}
      >
        <div className='rce-mbox-body' onContextMenu={props.onContextMenu}>
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0
            }}
          >
            {props.customActions?.map((Item) => {
              return (
                <Button
                  {...Item}
                  size={'small'}
                  type={'text'}
                  style={{
                    color: 'gray'
                  }}
                />
              )
            })}

            {isUUID(props.id as string) && props.position === 'left' && (
              <>
                <Badge
                  count={socialValues.likes}
                  size={'small'}
                  offset={[-5, 6]}
                  color={'#18563b'}
                >
                  <Button
                    style={{
                      color: 'gray'
                    }}
                    icon={<GoThumbsup />}
                    id={'id' + props.id}
                    type={'text'}
                    loading={loadingLikeDislikeCount}
                    // disabled={exists && !existAsLike}
                    onClick={onClickLike}
                  />
                </Badge>
                <Badge
                  count={socialValues.dislikes}
                  size={'small'}
                  offset={[-5, 6]}
                >
                  <Button
                    style={{
                      color: 'gray'
                    }}
                    icon={<GoThumbsdown />}
                    id={'id' + props.id}
                    type={'text'}
                    loading={loadingLikeDislikeCount}
                    // disabled={exists && existAsLike}
                    onClick={onClickDislike}
                  />
                </Badge>
              </>
            )}

            {props.copyable && (
              <Button
                size={'small'}
                type={'text'}
                icon={<IoCopyOutline />}
                onClick={() => {
                  // copy prop.text to clipboard
                  navigator.clipboard.writeText(props.text)
                  setCopied(true)
                  setTimeout(() => {
                    setCopied(false)
                  }, 2000)
                }}
                style={{
                  color: 'gray'
                }}
              >
                {copied && 'Copied'}
              </Button>
            )}
          </div>

          {!props.retracted && props.forwarded === true && (
            <div
              className={classNames(
                'rce-mbox-forward',
                { 'rce-mbox-forward-right': props.position === 'left' },
                { 'rce-mbox-forward-left': props.position === 'right' }
              )}
              onClick={props.onForwardClick}
            >
              <RiShareForwardFill />
            </div>
          )}

          {!props.retracted && props.replyButton === true && (
            <div
              className={
                props.forwarded !== true
                  ? classNames(
                      'rce-mbox-forward',
                      { 'rce-mbox-forward-right': props.position === 'left' },
                      { 'rce-mbox-forward-left': props.position === 'right' }
                    )
                  : classNames(
                      'rce-mbox-forward',
                      { 'rce-mbox-reply-btn-right': props.position === 'left' },
                      { 'rce-mbox-reply-btn-left': props.position === 'right' }
                    )
              }
              onClick={props.onReplyClick}
            >
              <MdMessage />
            </div>
          )}

          {!props.retracted && props.removeButton === true && (
            <div
              className={
                props.forwarded === true
                  ? classNames(
                      'rce-mbox-remove',
                      { 'rce-mbox-remove-right': props.position === 'left' },
                      { 'rce-mbox-remove-left': props.position === 'right' }
                    )
                  : classNames(
                      'rce-mbox-forward',
                      { 'rce-mbox-reply-btn-right': props.position === 'left' },
                      { 'rce-mbox-reply-btn-left': props.position === 'right' }
                    )
              }
              onClick={props.onRemoveMessageClick}
            >
              <MdDelete />
            </div>
          )}

          {(props.title || props.avatar) && (
            <div
              style={{ ...(props.titleColor && { color: props.titleColor }) }}
              onClick={props.onTitleClick}
              className={classNames('rce-mbox-title', {
                'rce-mbox-title--clear': props.type === 'text'
              })}
            >
              {props.avatar && (
                <Avatar letterItem={props.letterItem} src={props.avatar} />
              )}
              {props.title && <span>{props.title}</span>}
            </div>
          )}

          {props.type === 'text' && (
            <div
              className={classNames('rce-mbox-text', {
                'rce-mbox-text-retracted': props.retracted,
                left: props.position === 'left',
                right: props.position === 'right'
              })}
            >
              {props.retracted && <MdBlock />}
              <Flex justify={'flex-start'}>
                <div style={{ flexGrow: 1 }}>
                  {props.loading && (
                    <Spin size={'small'} style={{ paddingRight: 10 }} />
                  )}
                  <Markdown
                    components={{ a: LinkRenderer }}
                    remarkPlugins={[remarkGfm]}
                  >
                    {props.text}
                  </Markdown>
                </div>
              </Flex>
            </div>
          )}

          {props.type === 'meetingLink' && (
            <div className='rce-mtlink'>
              <div className='rce-mtlink-content'>
                <div className='rce-mtlink-item'>
                  <div className='rce-mtlink-title'>
                    <Flex justify={'flex-start'}>
                      {props.loading && (
                        <Spin size={'small'} style={{ paddingRight: 10 }} />
                      )}
                      <div style={{ flexGrow: 1 }}>
                        <Markdown
                          components={{ a: LinkRenderer }}
                          remarkPlugins={[remarkGfm]}
                        >
                          {props.text}
                        </Markdown>
                      </div>
                    </Flex>
                  </div>
                </div>
                <div className='rce-mtlink-btn'>
                  {props?.actionButtons?.map(
                    (Item: MeetingLinkActionButtons) => {
                      return (
                        <div
                          className='rce-mtlink-btn-content'
                          onClick={() =>
                            Item.onClickButton(props?.meetingID ?? '')
                          }
                        >
                          <Item.Component />
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            title={props.statusTitle}
            className={classNames(
              'rce-mbox-time',
              { 'rce-mbox-time-block': thatAbsoluteTime },
              { 'non-copiable': !props.copiableDate }
            )}
            data-text={props.copiableDate ? undefined : dateText}
          >
            {props.copiableDate &&
              props.date &&
              (props.dateString || format(props.date))}
            {props.status && (
              <span className='rce-mbox-status'>
                {props.status === 'waiting' && <MdAccessTime />}

                {props.status === 'sent' && <MdCheck />}

                {props.status === 'received' && <IoIosDoneAll />}

                {props.status === 'read' && <MdDoneAll color='#4FC3F7' />}
              </span>
            )}
          </div>
        </div>

        {notch &&
          (props.position === 'right' ? (
            <svg
              style={props.notchStyle}
              className={classNames('rce-mbox-right-notch', {
                'message-focus': focus
              })}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
            >
              <path d='M0 0v20L20 0' />
            </svg>
          ) : (
            <div>
              <svg
                style={props.notchStyle}
                className={classNames('rce-mbox-left-notch', {
                  'message-focus': focus
                })}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <defs>
                  <filter id='filter1' x='0' y='0'>
                    <feOffset
                      result='offOut'
                      in='SourceAlpha'
                      dx='-2'
                      dy='-5'
                    />
                    <feGaussianBlur
                      result='blurOut'
                      in='offOut'
                      stdDeviation='3'
                    />
                    <feBlend in='SourceGraphic' in2='blurOut' mode='normal' />
                  </filter>
                </defs>
                <path d='M20 0v20L0 0' filter='url(#filter1)' />
              </svg>
            </div>
          ))}
      </div>
    </div>
  )
}

export default MessageBox
