import React, { FC } from 'react'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { searchPlugin } from '@react-pdf-viewer/search'

import { Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/search/lib/styles/index.css'

interface IProps {
  fileUrl: string
  page: number
}

const PDFViewer: FC<IProps> = (props) => {
  const { fileUrl, page } = props
  const searchPluginInstance = searchPlugin({
    // keyword: ['stc', 'Annual', 'report', 'revenue', {
    //     keyword: 'SAR',
    //     matchCase: true,
    // },],
  })
  return (
    <Viewer
      fileUrl={fileUrl}
      initialPage={page - 1}
      plugins={[searchPluginInstance]}
    />
  )
}
export default PDFViewer
