import {
  Context,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Chat, IMessageListData } from '../../../../models/message'
import { api, useGetChatsHistoryQuery } from '../../../../redux/apiSlice'

type ContextType = {
  current: IMessageListData
  isLoading?: boolean
  chatList: Chat[]
  selectConversation: (id: string) => void
  newChat: (data?: Chat) => void
}
export const MessageListContext: Context<ContextType> = createContext({
  current: {} as IMessageListData,
  chatList: [] as Chat[],
  selectConversation: (id: string) => {},
  newChat: (chat?: Chat) => {}
})

export const MessageListProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [getMessagesFromChat, { data, isFetching }] =
    api.endpoints?.getMessagesFromChat?.useLazyQuery()

  const { data: chatHistory } = useGetChatsHistoryQuery('', {
    // refetchOnMountOrArgChange: true
  })

  const [chatList, setChatList] = useState<Chat[]>([])

  useEffect(() => {
    if (chatHistory && chatHistory.length > 0) {
      setChatList(chatHistory)
    }
  }, [chatHistory])

  const [messageListData, setMessageListData] = useState<IMessageListData>({
    messages: [],
    chatId: undefined
  })

  const selectConversation = useCallback(
    (id: string) => {
      getMessagesFromChat(id)
    },
    [getMessagesFromChat]
  )

  useEffect(() => {
    setMessageListData((temData) => {
      return { ...temData, messages: data || [], chatId: data?.[0]?.chat_id }
    })
  }, [data])

  useEffect(() => {
    console.log('current', messageListData)
  }, [messageListData])

  const newChat = useCallback(
    (data?: Chat) => {
      if (data) {
        setChatList((temData) => [...temData, data])
      } else {
        setMessageListData({ messages: [], chatId: undefined })
      }
    },
    [setChatList, setMessageListData]
  )

  return (
    <MessageListContext.Provider
      value={{
        current: messageListData,
        chatList: chatList,
        isLoading: isFetching,
        selectConversation,
        newChat
      }}
    >
      {children}
    </MessageListContext.Provider>
  )
}

export const useMessageList = () => {
  const context = useContext(MessageListContext)
  if (context === undefined) {
    throw new Error('useMessageList must be used within a MessageListProvider')
  }
  return context
}
