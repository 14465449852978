import styled from 'styled-components'
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  TableColumnsType,
  Tag,
  Typography,
  Upload,
  UploadProps
} from 'antd'
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetTenantsQuery,
  useGetUsersQuery,
  useUpdateUserMutation
} from '../../redux/apiSlice'
import { IUser } from '../../models'
import React, { useState } from 'react'
import { BiPencil, BiPlus, BiTrash } from 'react-icons/bi'
import { InboxOutlined } from '@ant-design/icons'
import { config } from '../../services/config'

const Wrapper = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow-y: scroll;
`
const EMPTY_PASS = '********'
const Users = () => {
  const { data, isLoading } = useGetUsersQuery('', {})

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [editing, setEditing] = useState<IUser | undefined>(undefined)

  const [createUser, { isLoading: isCreating }] = useCreateUserMutation()
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()

  const { data: tenants } = useGetTenantsQuery('', {})

  const columns: TableColumnsType<IUser> = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (value, record) => (
        <Typography.Text strong>{value}</Typography.Text>
      )
    },
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => (
        <Flex>
          <Typography.Text>{`${record.firstname} ${record.lastname}`}</Typography.Text>
        </Flex>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (value, record) => <a href={`mailto:${value}`}>{value}</a>
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) =>
        text === 'admin' ? (
          <Tag color={'red'}>Admin</Tag>
        ) : (
          <Tag color={'blue'}>Client</Tag>
        )
    },
    {
      title: 'Tenant',
      dataIndex: 'tenant_id',
      key: 'tenant_id',
      render: (value, record) => {
        const tenant = tenants?.find((t) => t.id === value)
        return <Typography.Text>{tenant?.name}</Typography.Text>
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (value, record) => (
        <Tag color={value ? 'green' : 'red'}>{value ? 'Yes' : 'No'}</Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Flex>
          <Button
            type={'default'}
            size={'small'}
            icon={<BiPencil />}
            onClick={() => {
              setEditing(record)
              form.setFieldsValue(record)
              form.setFieldValue('password', EMPTY_PASS)
              setIsModalOpen(true)
            }}
          ></Button>
          <Popconfirm
            title={'Are you sure?'}
            onConfirm={() => {
              deleteUser(record.id)
            }}
          >
            <Button
              type={'default'}
              size={'small'}
              danger
              icon={<BiTrash />}
              style={{ marginLeft: 10 }}
            ></Button>
          </Popconfirm>
        </Flex>
      )
    }
  ]

  const handleCreate = async (values: any) => {
    console.log({ values })
    if (editing) {
      if (values.password === EMPTY_PASS) {
        delete values.password
      }
      await updateUser({ ...editing, ...values })
    } else {
      await createUser({ ...values })
    }
    setEditing(undefined)
    form.resetFields()
    setIsModalOpen(false)
  }

  const { Dragger } = Upload

  const draggerProps: UploadProps = {
    name: 'file',
    multiple: false,
    action: `${config.BASE_URL}settings/users/upload`,
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    style: { height: 200 }
  }

  return (
    <Wrapper>
      <Typography.Title level={2}>User Directory</Typography.Title>
      <Flex justify={'flex-end'} style={{ paddingBottom: 20 }}>
        <Button
          type={'primary'}
          icon={<BiPlus />}
          onClick={() => setIsModalOpen(true)}
        >
          New User
        </Button>
      </Flex>
      <Modal
        open={isModalOpen}
        footer={<></>}
        onCancel={() => {
          setIsModalOpen(false)
          form.resetFields()

          setEditing(undefined)
        }}
        width={1200}
      >
        <Typography.Title level={3}>
          {editing ? 'Edit User' : 'New User'}
        </Typography.Title>
        <Row gutter={24}>
          <Col span={14}>
            <Typography.Title level={4}>Single User Creation</Typography.Title>
            <Form form={form} onFinish={handleCreate} layout={'vertical'}>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label={'Username'} name={'username'} required>
                    <Input disabled={!!editing} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={'Password'} name={'password'} required>
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item label={'Role'} name={'role'} required>
                    <Select>
                      <Select.Option value={'admin'}>Admin</Select.Option>
                      <Select.Option value={'client'}>Client</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18}>
                  <Form.Item
                    label={'Email'}
                    name={'email'}
                    required
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter a valid email'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={10}>
                  <Form.Item label={'First Name'} name={'firstname'}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label={'Last Name'} name={'lastname'}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label={'Active'}
                    name={'active'}
                    valuePropName={'checked'}
                    initialValue={true}
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item label={'Tenant'} name={'tenant_id'}>
                    <Select>
                      {tenants?.map((t) => (
                        <Select.Option value={t.id}>{t.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  loading={isCreating || isUpdating}
                >
                  {editing ? 'Update User' : 'Create New User'}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={10} style={{ borderLeft: 'solid 1px #d9d9d9' }}>
            {editing ? (
              <Flex
                justify={'center'}
                align={'center'}
                style={{ height: 300, color: '#aaaaaa' }}
              >
                {' '}
                Bulk upload not supported in Editing Mode
              </Flex>
            ) : (
              <Dragger {...draggerProps}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  Bulk import users from a CSV file
                </p>
                <p className='ant-upload-hint'>
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            )}
          </Col>
        </Row>
      </Modal>
      <Table columns={columns} dataSource={data} loading={isLoading} />
    </Wrapper>
  )
}
export default Users
