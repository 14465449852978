import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'services/config'
import { IAccess, ICredentials, IUser, Tenant } from '../models'

import { RFile } from 'models/file'
import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit'
import { redirect } from 'react-router-dom'
import {
  Chat,
  Filter,
  IMessageSocialInput,
  ISocial,
  ISocialInput,
  ISocialStatus,
  RawMessage
} from '../models/message'

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401 || action.payload.status === 403) {
        console.log('Bad permissions')
        redirect('/login')
      }
    }

    return next(action)
  }

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const accessToken = localStorage.getItem('AccessToken')
      if (accessToken && endpoint !== 'login') {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    },

    credentials: 'same-origin'
  }),
  tagTypes: ['User', 'Chat', 'Message', 'File', 'Filter', 'Tenant'],
  endpoints: (builder) => ({
    getFiles: builder.query<RFile[], string>({
      query: (tenant_id: string) => `/settings/files/${tenant_id}`,
      providesTags: [{ type: 'File', id: 'LIST' }]
    }),
    deleteFile: builder.mutation({
      query: (args: { tenant_id: string; file_id: string }) => ({
        url: `/settings/files/${args.tenant_id}/${args.file_id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'File', id: 'LIST' }]
    }),
    getTenants: builder.query<Tenant[], any>({
      query: () => `/settings/tenants`,
      providesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),
    createTenant: builder.mutation<Tenant, Tenant>({
      query: (args: Tenant) => ({
        url: `/settings/tenants`,
        method: 'POST',
        body: args
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),
    updateTenant: builder.mutation<Tenant, Tenant>({
      query: (args: Tenant) => ({
        url: `/settings/tenants/${args.id}`,
        params: {
          tenant_id: args.id
        },
        method: 'PUT',
        body: args
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),
    deleteTenant: builder.mutation({
      query: (id: string) => ({
        url: `/settings/tenants/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Tenant', id: 'LIST' }]
    }),

    getFilters: builder.query<Filter, string>({
      query: (tenant_id: string) => `/knowledgebase/filters/${tenant_id}`,
      providesTags: [{ type: 'Filter', id: 'LIST' }]
    }),
    sendQuery: builder.mutation<string, string>({
      query: (args: string) => ({
        url: '/assistant/query',
        method: 'POST',
        body: args
      })
    }),
    login: builder.mutation<IAccess, ICredentials>({
      query: (args: ICredentials) => {
        let bodyFormData = new FormData()
        bodyFormData.append('username', args.username)
        bodyFormData.append('password', args.password)
        return {
          url: '/login/token',
          method: 'POST',
          body: bodyFormData
        }
      },
      invalidatesTags: [
        { type: 'User', id: 'current' },
        { type: 'Chat', id: 'LIST' },
        { type: 'Message', id: 'LIST' }
      ]
    }),
    getCurrentUser: builder.query<IUser, string>({
      query: (token: string) => ({
        url: 'login/users/me',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: [{ type: 'User', id: 'current' }]
    }),
    getAPIStatus: builder.query<string, string>({
      query: () => ({
        url: `${config.LLM_URL}ping`
      })
    }),
    getChatsHistory: builder.query<Chat[], string>({
      query: () => ({
        url: 'chatbot/chats'
      }),
      providesTags: [{ type: 'Chat', id: 'LIST' }]
    }),
    deleteChat: builder.mutation({
      query: (id: string) => ({
        url: `/chatbot/chats/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Chat', id: 'LIST' }]
    }),
    getMessagesFromChat: builder.query<RawMessage[], string>({
      query: (chat_id: string) => ({
        url: `chatbot/chats/${chat_id}/messages`
      }),
      providesTags: [{ type: 'Message', id: 'LIST' }]
    }),
    setMetadataForFile: builder.mutation({
      query: (args: {
        tenant_id: string
        file_id: string
        metadata: object
      }) => ({
        url: `/knowledgebase/update-metadata/${args.tenant_id}/${args.file_id}`,
        method: 'POST',
        body: JSON.stringify(args.metadata)
      }),
      invalidatesTags: [
        { type: 'Filter', id: 'LIST' },
        { type: 'File', id: 'LIST' }
      ]
    }),
    addRemoveLikeDislike: builder.mutation<ISocial, ISocialInput>({
      query: (args: ISocialInput) => {
        return {
          url: `/chatbot/message/${args.messageId}/user/${args.userId}?is_like=${args.isLike}`,
          method: args.remove ? 'DELETE' : 'POST'
        }
      }
    }),
    getLikeDislikeCount: builder.query<ISocial, string>({
      query: (message_id: string) => {
        return {
          url: `chatbot/messages/${message_id}/count`,
          method: 'GET'
        }
      }
    }),
    isLikesDislikeExists: builder.query<ISocialStatus, ISocialInput>({
      query: (args: ISocialInput) => {
        return {
          url: `chatbot/message/${args.messageId}/user/${args.userId}`,
          method: 'GET'
        }
      }
    }),
    getUsers: builder.query<IUser[], any>({
      query: () => ({
        url: `/settings/users`,
        method: 'GET'
      }),
      providesTags: [{ type: 'User', id: 'LIST' }]
    }),
    createUser: builder.mutation<IUser, IUser>({
      query: (args: IUser) => ({
        url: `/settings/users`,
        method: 'POST',
        body: args
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }]
    }),
    updateUser: builder.mutation<IUser, IUser>({
      query: (args: IUser) => ({
        url: `/settings/users/${args.id}`,
        method: 'PUT',
        body: args
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }]
    }),
    deleteUser: builder.mutation({
      query: (id: string) => ({
        url: `/settings/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }]
    })
  })
})

export const {
  useSendQueryMutation,
  useLoginMutation,
  useGetCurrentUserQuery,
  useGetTenantsQuery,
  useCreateTenantMutation,
  useUpdateTenantMutation,
  useDeleteTenantMutation,
  useGetFilesQuery,
  useGetAPIStatusQuery,
  useGetChatsHistoryQuery,
  useDeleteChatMutation,
  useSetMetadataForFileMutation,
  useDeleteFileMutation,
  useGetFiltersQuery,
  useGetMessagesFromChatQuery,
  useAddRemoveLikeDislikeMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useIsLikesDislikeExistsQuery
} = api
