import { Auth, IUser } from 'models/auth'

const getUserFromLocalStorage = (): IUser | null => {
  const currentUser = localStorage.getItem('currentUser')
  if (currentUser) {
    try {
      const userObject = JSON.parse(currentUser)
      // Additional check to ensure the parsed object conforms to IUser structure can be added here
      return userObject as IUser
    } catch (error) {
      // Handle error for invalid JSON string
      console.error('Error parsing currentUser from localStorage', error)
    }
  }
  return null
}

export const authService: Auth = {
  isAuthenticated: () => {
    return localStorage.getItem('isAuthenticated') === 'true'
  },
  user: getUserFromLocalStorage(),
  async Signout() {
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('currentUser')
    this.user = null
  }
}
